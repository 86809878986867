<template>
  <section class="section section-hero">
    <div class="section-hero-bg">
      <SectionTitle
        icon="icon-services"
        h2="Weboldalán, közösségi oldalain a legfrissebb bejegyzések is több hónaposak? <br class='d-none d-lg-block'> Nincs ideje, energiája naprakészen tartani az információkat vagy újakat kitalálni?"
        h2small=""
        createdate=""
      />
    </div>
    <div class="section section-hero-body section-hero-services">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 order-2 order-lg-1">
            <small class="h2-small">
              Folyamatos online jelenléttel, kapcsolattartással fenntartható a
              már meglévő ügyfelek érdeklődése és az új látogatókból is
              könnyebben válik vásárló egy tartalmas, folyamatosan frissülő
              weboldallal.
            </small>

            <div class="box-contents">
              <h3 class="d-none d-lg-block">// Support szolgáltatás</h3>
              <div id="bovebben-a-support-szolgaltatasrol"></div>
              <br />
              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#bovebben-a-support-szolgaltatasrol"
              >
                <span># Bővebben a support szolgáltatásról</span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link btn-link-cta"
                to="#megrendeles"
              >
                <span class="ps-2 pe-2"># A szolgáltatás megrendelése</span>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-lg-6 order-1 order-lg-2">
            <div class="col-6 col-lg-12 m-auto mb-3 mb-lg-0">
              <img
                src="@/assets/img/all/img-box-services-support-left.png"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Bővebben a support szolgáltatásról"
    h2small="Alapfogalmak, definíciók, mire jó ez az egész?"
    createdate=""
  />

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3>// Mi is az a support szolgáltatás?</h3>
          <p class="lead">
            Egy jól működő weboldal folyamatos karbantartást és tartalmi
            frissítést igényel. Kérdések, problémák, új igények bármikor
            felmerülhetnek hiszen minden cég változik. <br />
            A landing oldalakat is célszerű különböző szempontok szerint
            újragondolni, hogy minél sikeresebb legyen a konverzió.
          </p>
          <h3>// Mi is előnye a support szolgáltatás?</h3>
          <div class="lead">
            <ul>
              <li>
                A support révén nyújtott idő biztosítja, hogy a problémák,
                módosítások időben megtörénjenek
              </li>
              <li>Rugalmasan felhasználható</li>
              <li>Ár/érték arányban kedvezőbb, mint a listaár</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Technológiák, amiket használunk"
    h2small=""
    createdate=""
  />

  <section class="section section-body section-body-icons">
    <div class="container">
      <div class="row">
        <div class="col-4 col-lg-2 mt-3 text-center">
          <div id="megrendeles"></div>
          <img src="@/assets/img/all/icon-html5.svg" alt="HTML5" />
          <h3 class="mt-2"># HTML</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-css3.svg" alt="CSS3" />
          <h3 class="mt-2"># CSS</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-scss.svg" alt="SCSS" />
          <h3 class="mt-2"># SCSS</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-javascript.svg" alt="JavaScript" />
          <h3 class="mt-2"># JavaScript</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-bootstrap5.svg" alt="Bootstrap 5" />
          <h3 class="mt-2"># Bootstrap</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-vuejs.svg" alt="VUE.JS 3" />
          <h3 class="mt-2"># VUE.JS</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-sketch.svg" alt="Sketch" />
          <h3 class="mt-2"># Sketch</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-gwd.svg" alt="Google Web Designer" />
          <h3 class="mt-2"># GWD</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-photoshop.svg" alt="Photoshop" />
          <h3 class="mt-2"># Photoshop</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-firebase.svg" alt="Firebase" />
          <h3 class="mt-2"># Firebase</h3>
        </div>

        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-php.svg" alt="Php" />
          <h3 class="mt-2"># php</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img
            src="@/assets/img/all/icon-vscode.svg"
            alt="Visual Studio Code"
          />
          <h3 class="mt-2"># VS Code</h3>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Support szolgáltatási <br class='d-none d-lg-block'> irányárak, megrendelés"
    h2small="Kedvező árakkal, egyszerű fizetési megoldásokkal rendelheti meg <br class='d-none d-lg-block'> support szolgáltatásunkat"
    createdate=""
  />

  <section v-if="sent" class="section section-price-box">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="lead text-center">
            Köszönjük a megkeresést, hamarosan jelentkezünk!
          </p>
        </div>

        <div class="col-12 text-center">
          <router-link
            @click="hidden = !hidden"
            class="btn pt-4 pb-4 mt-3 mb-5"
            to="/szolgaltatasaink/support-szolgaltatas"
          >
            <span class="ps-5 pe-5">Új megrendelés? </span>
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <section v-if="!sent" class="section section-price-box">
    <div class="container">
      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice30"
            data-bs-target="#collapseprice30"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Havi munkaórák (óra)</h5>
                <p class="collapse show" id="collapseprice30">
                  Az előre tervezett havi munkaórák száma, amely időt dedikáltan
                  az Ön átal kért munkákra fordítjuk, valamint ebből az
                  időkeretből végezzük a szükséges feladatokat (például:
                  biztonsági frissítések). A munkaórák a következő fizetési
                  időszakra átvihetők, de maximum 6 hónapig használhatóak fel.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center w-100"
          >
            <h4 class="w-75">
              <input
                type="range"
                class="form-hours"
                min="1"
                max="40"
                id="hours"
                v-model="hours"
                v-if="!showform"
              />
              {{ hours }}
              <span>óra</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice40"
            data-bs-target="#collapseprice40"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />

                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Díjfizetés ütemezése (1/6/12 hónap)</h5>
                <p class="collapse show" id="collapseprice40">
                  A díj előre fizetendő. Ha 6 vagy 12 hónapra előre kéri support
                  szoláltatásunkat akkor kedvezményt (10/20%) biztosítunk.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioSchedule"
                id="option0-schedule"
                value="1"
                v-model="schedule"
              />
              <label class="btn pt-3 pb-3" for="option0-schedule">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> 1 </span>
              </label>
            </div>
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioSchedule"
                id="option1-schedule"
                value="6"
                v-model="schedule"
              />
              <label class="btn pt-3 pb-3" for="option1-schedule">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> 6 </span>
              </label>
            </div>
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioSchedule"
                id="option2-schedule"
                value="12"
                v-model="schedule"
              />
              <label class="btn pt-3 pb-3" for="option2-schedule">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> 12 </span>
              </label>
            </div>

            <h4 v-if="showform">
              {{ schedule }}
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice90"
            data-bs-target="#collapseprice90"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />

                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Online konzultáció (igen/nem)</h5>
                <p class="collapse show" id="collapseprice90">
                  Ha úgy érzi, hogy szüksége van bővebb tájékoztatásra akkor a
                  válaszott online platformon bővebb tájékoztatást nyújtunk,
                  külön díj ellenében (+7.620 Ft), a support szolgáltatásunkkal
                  kapcsolatban (30perc).
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioonlinekonz"
                id="option0-onlinekonz"
                value="false"
                v-model="onlinekonz"
              />
              <label class="btn pt-3 pb-3" for="option0-onlinekonz">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> nem </span>
              </label>
            </div>
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioonlinekonz"
                id="option1-onlinekonz"
                value="true"
                v-model="onlinekonz"
              />
              <label class="btn pt-3 pb-3" for="option1-onlinekonz">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> igen </span>
              </label>
            </div>

            <div v-if="showform">
              <h4 v-if="!onlinekonz">nem</h4>
              <h4 v-if="onlinekonz">igen</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice100"
            data-bs-target="#collapseprice100"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Ár (Ft)</h5>
                <p class="collapse show" id="collapseprice100">
                  A díj minden esetben előre fizetendő. Az árak a 27%-s áfát
                  tartalmazzák.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <div class="row">
              <div class="col-12">
                <h4 class="w-100 mt-2">
                  Összesen: {{ totalpricecalculate }} <span>Ft</span>
                </h4>
              </div>
              <div class="col-12">
                <h4 class="w-100 mt-2">
                  <small>
                    ({{ Math.round(totalpricecalculate / schedule) }}
                    <span>Ft/hónap)</span>
                  </small>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!showform" class="row equal-cols">
        <div class="col price-col price-col-1"></div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <button @click="showform = true" class="btn btn-send pt-4 pb-4">
              <span class="ps-4 pe-4 text-center">
                Support szolgáltatás megrendelése
              </span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="showform" class="row">
        <div class="d-none d-lg-block col-lg-2 price-col-1"></div>
        <div class="col-12 col-lg-8 price-col-1">
          <Form
            v-if="!sent"
            @submit="sendForm"
            :validation-schema="schema"
            v-slot="{ errors }"
            class="contact-us-form"
          >
            <div class="form-group">
              <label for="name">// Cégnév, kapcsolattartó</label>
              <Field hidden name="hours" :value="this.hours" />
              <Field hidden name="schedule" :value="this.schedule" />
              <Field hidden name="onlinekonz" :value="this.onlinekonz" />
              <Field hidden name="totalprice" :value="totalpricecalculate" />

              <Field
                name="name"
                as="input"
                placeholder="Kérjük, adja meg cégnevét és a kapcsolattartó nevét!"
                class="form-control"
                :class="{ 'is-invalid': errors.name }"
              />
              <div class="invalid-feedback">{{ errors.name }}</div>
            </div>

            <div class="form-group">
              <label for="email">// Email cím</label>
              <Field
                name="email"
                as="input"
                placeholder="Kérjük, adja meg email címét!"
                class="form-control"
                :class="{ 'is-invalid': errors.email }"
              />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>

            <div class="form-group">
              <label for="phone_number">// Telefonszám</label>
              <Field
                name="phone_number"
                as="input"
                placeholder="Kérjük, adja meg telefonszámát!"
                class="form-control"
                :class="{ 'is-invalid': errors.phone_number }"
              />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>

            <div class="form-group">
              <label for="when">// Mikor keressük telefonon?</label>
              <Field
                name="when"
                as="select"
                class="form-control"
                :class="{ 'is-invalid': errors.service }"
              >
                <option value="" disabled selected>
                  Kérjük, adja meg mikor kereshetjük telefonon?
                </option>
                <option value="9-12">9-12h</option>
                <option value="12-16">12-16h</option>
                <option value="Ne keressenek">
                  Nem kérek telefonos hívást
                </option>
              </Field>
              <div class="invalid-feedback">{{ errors.service }}</div>
            </div>

            <div class="form-group">
              <label for="message">// Üzenet</label>
              <Field
                name="message"
                as="textarea"
                placeholder="Üzenet"
                class="form-control form-message"
                value=""
                :class="{ 'is-invalid': errors.message }"
              />
              <ErrorMessage name="message" />
              <div class="invalid-feedback">{{ errors.message }}</div>
            </div>

            <div class="form-check">
              <Field
                name="accept"
                type="checkbox"
                id="accept"
                value="true"
                class="form-check-input custom-control-input"
                :class="{ 'is-invalid': errors.accept }"
              />
              <label for="accept" class="form-check-label custom-control-label">
                Megismertem és elfogadom az <br />
                <router-link
                  @click="hidden = !hidden"
                  class="btn-privacy-link"
                  to="/adatvedelmi-tajekoztato"
                >
                  <span>adatvédelmi nyilatkozatot</span>
                </router-link>
              </label>
            </div>

            <div class="form-group text-center mt-5">
              <div class="d-grid col-lg-8 m-auto">
                <button type="submit" class="btn pt-4 pb-4 mb-2">
                  <span>Üzenet elküldése</span>
                </button>
                Minden mező kitöltése kötelező. <br />
                A megadott adatokat csak és kizárólag a kapcsolatfelvételhez
                használjuk fel.
              </div>
            </div>
          </Form>
        </div>
        <div class="d-none d-lg-block col-lg-2 price-col-1"></div>
      </div>
    </div>
  </section>

  <SectionTovabbiSzolgaltatasaink actuale="3" />
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import SectionTovabbiSzolgaltatasaink from "../../components/SectionTovabbiSzolgaltatasaink.vue";

export default {
  components: {
    SectionTitle,
    SectionTovabbiSzolgaltatasaink,
    Form,
    Field,
  },

  data() {
    const schema = Yup.object().shape({
      name: Yup.string().required("A név kötelező!"),
      email: Yup.string()
        .required("Az email kötelező!")
        .email("Az email érvénytelen!"),
      phone_number: Yup.string().required("A telefonszám kötelező!"),
      when: Yup.string().required("Kötelező mező!"),
      message: Yup.string(),
      accept: Yup.string().required(
        "Fogadja el az adatkezelési irányelveinket!"
      ),
    });

    return {
      hours: 1,
      price15: 7620,
      price610: 6350,
      price1120: 5080,
      showform: false,
      onlinekonz: false,
      schedule: 1,
      onlineprice: 7620,
      schema,
      sent: false,
    };
  },

  computed: {
    totalpricecalculate() {
      let totalprice = 0;

      if (this.hours <= 5) {
        totalprice += this.hours * this.price15;
      }

      if (this.hours > 5 && this.hours <= 10) {
        totalprice += this.price610 * (this.hours - 5) + 5 * this.price15;
      }

      if (this.hours > 10) {
        totalprice +=
          this.price1120 * (this.hours - 10) +
          5 * this.price15 +
          5 * this.price610;
      }

      if (this.onlinekonz === "true") totalprice += this.onlineprice;

      if (this.schedule === "1") {
        totalprice *= 1;
      }

      if (this.schedule === "6") {
        totalprice *= 6;
        totalprice *= 0.9;
      }

      if (this.schedule === "12") {
        totalprice *= 12;
        totalprice *= 0.8;
      }

      return totalprice;
    },
  },

  methods: {
    sendForm(e) {
      axios
        .post("https://arda.hu/api/send_form_services_support.php", {
          name: e.name,
          email: e.email,
          phone_number: e.phone_number,
          when: e.when,
          message: e.message,
          accept: e.accept,
          hours: e.hours,
          onlinekonz: e.onlinekonz,
          schedule: e.schedule,
          totalprice: e.totalprice,
        })
        .then(() => {
          this.sent = true;
        });
    },

    onReset() {
      this.sent = false;
    },
  },
};
</script>
